import React from "react";
import styles from "./headerLogo.module.scss";
import LogoUAZ from "shared/svgs/logoUAZ.svg";
import { constants } from "shared/constants/index";
import pl from "shared/messages/pl";

interface HeaderLogoProps {
    noLink?: boolean;
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({ noLink }) => {
    const image = <img className={styles.logo} src={LogoUAZ} alt={pl.common.logoAlt} />;

    if (noLink) {
        return <div className={styles.logoLink}>{image}</div>;
    }

    return (
        <a href={constants.header.uazMainPage} className={styles.logoLink}>
            {image}
        </a>
    );
};

export default HeaderLogo;
