// PWA version used for cache and IndexedDB versioning
// and for forcing service worker updates
export const pwaCurrentVersionNumber = 3;

export const constants = {
    dateFormat: "dd.MM.yyyy",
    header: {
        uazMainPage: "https://www.ubezpieczeniaodadoz.pl/"
    },
    footer: {
        privacyPolicy: "/" // TODO : Add link
    },
    contact: {
        phone: "41 333 50 90"
    },
    mobileAppBannerStorageKey: "UAZ_SK_isMobileAppBannerClosed",
    pwa: {
        currentVersionNumber: pwaCurrentVersionNumber,
        backPressedTimeIntervalInMilliseconds: 2000,
        backToLoginPageRedirectTimeout: 2000,
        indexedDB: {
            name: "UAZSK",
            storeNames: {
                user: "user",
                policies: "policies",
                policyFilesMetadata: "policyFilesMetadata"
            }
        },
        localStorage: {
            swUpdateHash: "swUpdateHash"
        },
        messages: {
            newSwFetched: "NEW_SW_FETCHED",
            swFetchFailed: "SW_FETCH_FAILED"
        },
        events: {
            swInstalledFirstTime: "swInstalledFirstTime",
            swUpdateAvailable: "swUpdateAvailable",
            swHashSaved: "swHashSaved",
            updateCheckerReady: "updateCheckerReady"
        },
        caches: {
            images: "images"
        },
        swUrl: `${process.env.PUBLIC_URL}/service-worker.js`,
        updateTimeoutInSeconds: 20
    }
};

export const validation = {
    imagesRegEx: /[^\s]+(.*?)\.(jpg|jpeg|png|JPG|JPEG|PNG)$/
};

export const events = {
    pwa: {
        registration: {
            updateFound: "SW_registration_updateFound",
            swUpdateAvailableDispatch: "SW_registration_swUpdateAvailableDispatch",
            swInstalledFirstTime: "SW_registration_swInstalledFirstTime"
        },
        updateChecker: {
            swUnsupported: "SW_updateChecker_swUnsupported",
            timeoutReached: "SW_updateChecker_timeoutReached",
            swFetchFailed: "SW_updateChecker_swFetchFailed",
            newSwFetched: "SW_updateChecker_swFetched",
            updateAfterConnectionChange: "SW_updateChecker_updateAfterConnectionChange",
            refreshAccepted: "SW_updateChecker_refreshAccepted",
            refreshDeclined: "SW_updateChecker_refreshDeclined",
            skippedWaiting: "SW_updateChecker_skippedWaiting",
            swControllerChanged: "SW_updateChecker_swControllerChanged",
            swInstallationPromptShown: "SW_updateChecker_swInstallationPromptShown",
            addedToHomeScreen: "SW_updateChecker_addedToHomeScreen"
        }
    },
    mobileAppBanner: {
        appStore: "mobileAppBanner_appStore",
        googlePlay: "mobileAppBanner_googlePlay"
    }
};
