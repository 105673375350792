import ReactDOM from "react-dom";
import App from "App";
import { BrowserRouter } from "react-router-dom";
import { defineCustomElements } from "@ionic/pwa-elements/loader";
import { stores, StoreContext } from "stores/Stores";
import { ClientContext, ErrorLogger } from "ecom-selfcare-apps-core";
import { clientContextValue } from "shared/contexts/ClientContextValue";
import { register as registerListeners } from "pwa/listeners";

ReactDOM.render(
    <ErrorLogger>
        <ClientContext.Provider value={clientContextValue}>
            <StoreContext.Provider value={stores}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </StoreContext.Provider>
        </ClientContext.Provider>
    </ErrorLogger>,
    document.getElementById("root")
);

defineCustomElements(window);
registerListeners();
