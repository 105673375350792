import React, { useEffect } from "react";
import { paths } from "./path";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import {
    PageWrapper,
    PoliciesWalletContainer,
    PolicyDetailsContainer,
    AuthProvider,
    UserProvider,
    LoginContainer,
    AccountActivationContainer,
    FloatingNotification,
    Loader,
    SimpleContentPage,
    AzureAdAuthService,
    LogService
} from "ecom-selfcare-apps-core";
import { useStore } from "stores/Stores";
import HeaderLogo from "shared/header/HeaderLogo";
import LoginCar from "assets/img/login-car.png";
import { ReactComponent as MobileAppIcon } from "assets/img/mobile-app-icon.svg";
import LogoUAZSK from "shared/svgs/logoUAZSK.svg";
import pl from "shared/messages/pl";
import { constants, events } from "shared/constants";
import NoPoliciesContact from "../policies/NoPoliciesContact";
import { Capacitor } from "@capacitor/core";
import { Network } from "@capacitor/network";
import { Toast } from "@capacitor/toast";
import { navigationHelper } from "pwa/listeners";
import { isAndroid, isIOS } from "react-device-detect";

export const Routes: React.FC<{}> = () => {
    const { authenticationStore, userStore, policyStore } = useStore();
    const history = useHistory();

    useEffect(() => {
        Network.addListener("networkStatusChange", async (status) => {
            if (status.connected) {
                await authenticationStore.refreshToken();
            }
        });
    }, [authenticationStore]);

    const mobileAppBanner =
        isAndroid || isIOS ? (
            <FloatingNotification
                storageKey={constants.mobileAppBannerStorageKey}
                header={pl.mobileAppBanner.header}
                text={pl.mobileAppBanner.text}
                icon={<MobileAppIcon />}
                action={{
                    link: isAndroid
                        ? process.env.REACT_APP_GOOGLE_PLAY_MOBILE_APP_URL
                        : process.env.REACT_APP_APP_STORE_MOBILE_APP_URL,
                    handler: () =>
                        LogService.logEvent({
                            name: isAndroid ? events.mobileAppBanner.googlePlay : events.mobileAppBanner.appStore
                        }),
                    text: pl.mobileAppBanner.action
                }}
                isMobileOnly
                isWebAppOnly
                rememberClosing
            />
        ) : null;

    return (
        <Switch>
            <Route
                path={paths.login}
                render={(props) => (
                    <>
                        <LoginContainer
                            {...props}
                            redirectAfterLoginLink={paths.policiesWallet}
                            history={history}
                            forgotPasswordReturnLink={window.location.origin}
                            capacitorChangePasswordPage={paths.changePassword}
                            capacitorCloseBrowserUrl={paths.backToLoginForm}
                            authenticationStore={authenticationStore}
                            logo={<img src={LogoUAZSK} alt={pl.common.logoAlt} />}
                            otherSection={<img src={LoginCar} alt={pl.common.heroImageAlt} />}
                            canResetPassword={async () => {
                                const status = await Network.getStatus();

                                if (!status.connected) {
                                    await Toast.show({
                                        text: pl.common.noInternetConnection
                                    });
                                }

                                return status.connected;
                            }}
                        />
                        {mobileAppBanner}
                    </>
                )}
            />

            <Route
                path={paths.accountActivation}
                render={(props) => (
                    <AccountActivationContainer
                        {...props}
                        history={history}
                        logo={<img src={LogoUAZSK} alt={pl.common.logoAlt} />}
                        heroImage={<img src={LoginCar} alt={pl.common.heroImageAlt} />}
                        userStore={userStore}
                        authenticationStore={authenticationStore}
                    />
                )}
            />

            <Route
                exact
                path={paths.changePassword}
                render={() => {
                    const azProvider = new AzureAdAuthService(window.location.origin + paths.backToLoginForm);
                    azProvider.resetPassword();
                    return (
                        <SimpleContentPage logo={<HeaderLogo noLink />}>
                            <Loader />
                        </SimpleContentPage>
                    );
                }}
            />

            <AuthProvider
                authenticationStore={authenticationStore}
                history={history}
                loginPagePath={paths.login}
                shouldLoginWithoutToken={async () => {
                    const status = await Network.getStatus();

                    return !status.connected;
                }}
            >
                <UserProvider
                    userStore={userStore}
                    authenticationStore={authenticationStore}
                    history={history}
                    loginPagePath={paths.login}
                >
                    <PageWrapper
                        userStore={userStore}
                        authenticationStore={authenticationStore}
                        logo={<HeaderLogo noLink={Capacitor.isNativePlatform()} />}
                        navigationHelper={navigationHelper}
                    >
                        <Switch>
                            <Route
                                exact
                                path={paths.policy}
                                render={(props) => {
                                    return (
                                        <PolicyDetailsContainer
                                            {...props}
                                            userStore={userStore}
                                            policyStore={policyStore}
                                            walletPath={paths.policiesWallet}
                                        />
                                    );
                                }}
                            />
                            <Route
                                exact
                                path={paths.policiesWallet}
                                render={(props) => (
                                    <PoliciesWalletContainer
                                        {...props}
                                        showAdvisorAgency={false}
                                        policyDetailsPath={paths.policy}
                                        policyStore={policyStore}
                                        userStore={userStore}
                                        noPoliciesView={true}
                                        history={history}
                                        noPoliciesContent={pl.policies.noPolicies.content}
                                        noPoliciesContact={<NoPoliciesContact />}
                                    />
                                )}
                            />
                            <Route render={() => <Redirect to={paths.policiesWallet} />} />
                        </Switch>
                        {mobileAppBanner}
                    </PageWrapper>
                </UserProvider>
            </AuthProvider>
        </Switch>
    );
};

export default Routes;
