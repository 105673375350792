import { createContext, useContext } from "react";
import {
    AuthenticationStore,
    DefaultAuthenticationStore,
    UserStore,
    DefaultUserStore,
    PolicyStore,
    DefaultPolicyStore
} from "ecom-selfcare-apps-core";

export interface IStore {
    authenticationStore: AuthenticationStore;
    userStore: UserStore;
    policyStore: PolicyStore;
}

export const stores: IStore = {
    authenticationStore: DefaultAuthenticationStore,
    userStore: DefaultUserStore,
    policyStore: DefaultPolicyStore
};

export const StoreContext = createContext(stores);

export const useStore = () => {
    return useContext(StoreContext);
};
