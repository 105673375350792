import React, { useState, useEffect, useRef, useCallback } from "react";
import { Loader, LogService } from "ecom-selfcare-apps-core";
import { ConnectionStatus, Network } from "@capacitor/network";
import { Capacitor } from "@capacitor/core";
import { pwaCurrentVersionNumber, constants, events } from "shared/constants";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import Swal from "sweetalert2";
import pl from "shared/messages/pl";

const UpdateAvailableDialog: React.FC = ({ children }) => {
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isCheckingUpdates, setIsCheckingUpdates] = useState(true);

    const isUpdateCausedByNetworkChange = useRef(false);
    const prevSwHash = useRef(localStorage.getItem(constants.pwa.localStorage.swUpdateHash));
    const lastLaunchSwHash = useRef(localStorage.getItem(constants.pwa.localStorage.swUpdateHash));
    const prevSwState = useRef<ServiceWorkerState | undefined>(undefined);
    const updateTimeout = useRef<NodeJS.Timeout | null>(null);

    const getServiceWorkerRegistration = async () => await navigator.serviceWorker.getRegistration();

    const displayUpdateAvailableAlert = async () => {
        const newSwHash = localStorage.getItem(constants.pwa.localStorage.swUpdateHash);

        if (!isUpdateCausedByNetworkChange.current || prevSwHash.current === newSwHash) {
            return;
        }

        // Save the current sw hash before we display an update prompt so we
        // don't repeat the prompt when user toggles the connection later
        prevSwHash.current = localStorage.getItem(constants.pwa.localStorage.swUpdateHash);

        const { title, description, refresh, cancel } = pl.pwa.updateAvailableAlert;

        const refreshPrompt = await Swal.fire({
            title,
            text: description,
            showCancelButton: true,
            confirmButtonText: refresh,
            cancelButtonText: cancel,
            icon: "question"
        });

        if (refreshPrompt.isConfirmed) {
            LogService.logEvent({
                name: events.pwa.updateChecker.refreshAccepted,
                properties: { pwaCurrentVersionNumber }
            });

            window.location.reload();
        } else {
            LogService.logEvent({
                name: events.pwa.updateChecker.refreshDeclined,
                properties: { pwaCurrentVersionNumber }
            });
        }
    };

    const handleUpdateAvailable = useCallback(async () => {
        const registration = await getServiceWorkerRegistration();

        // Send SKIP_WAITING signal to SW to apply downloaded update
        if (registration && registration.active && registration.waiting) {
            LogService.logEvent({
                name: events.pwa.updateChecker.skippedWaiting,
                properties: { pwaCurrentVersionNumber }
            });

            registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }

        await displayUpdateAvailableAlert();
    }, []);

    const handleNetworkChange = async (status: ConnectionStatus) => {
        if (!status.connected) {
            return;
        }

        LogService.logEvent({
            name: events.pwa.updateChecker.updateAfterConnectionChange,
            properties: { pwaCurrentVersionNumber }
        });

        const swRegistration = await navigator.serviceWorker.getRegistration();

        if (!swRegistration) {
            return;
        }

        // Flip the flag, so we only display the update
        // alert after connection change and only forcefully
        // reload the page after normal update on start
        isUpdateCausedByNetworkChange.current = true;

        // Somehow swRegistration.update() doesn't hit the corresponding
        // service worker's fetch event, so we explicitly call it to
        // update SW hash and only then update the SW
        await fetch(constants.pwa.swUrl);
        await swRegistration.update();
    };

    const handleControllerChange = useCallback(async () => {
        if (isRefreshing) {
            return;
        }

        const newSw = (await getServiceWorkerRegistration())?.active?.state;

        // Fire reload only when updating the service worker
        // and not on the initial install, as it would interrupt
        // user flow, forcing the page to refresh without any reason
        if (!isUpdateCausedByNetworkChange.current && prevSwState.current === "activated" && newSw === "activating") {
            LogService.logEvent({
                name: events.pwa.updateChecker.swControllerChanged,
                properties: { pwaCurrentVersionNumber }
            });

            setIsRefreshing(true);
            window.location.reload();
        }
    }, [isRefreshing]);

    const handleNewSwMessage = async (e: MessageEvent) => {
        // For now we are only handling swFetchFailed and newSwFetched messages
        if (e.data.type === constants.pwa.messages.swFetchFailed) {
            // The service worker fetch failed, so we cannot
            // apply any updates -> let the user in
            setIsCheckingUpdates(false);

            LogService.logEvent({
                name: events.pwa.updateChecker.swFetchFailed,
                properties: { pwaCurrentVersionNumber }
            });

            return;
        }

        if (e.data.type !== constants.pwa.messages.newSwFetched) {
            return;
        }

        LogService.logEvent({ name: events.pwa.updateChecker.newSwFetched, properties: { pwaCurrentVersionNumber } });

        const swHash = e.data.swHash;

        localStorage.setItem(constants.pwa.localStorage.swUpdateHash, swHash);

        // We have SW ready, it just needs install,
        // so reset the emergency timeout
        if (updateTimeout.current) {
            clearTimeout(updateTimeout.current);
        }

        // New service worker's hash is the same as
        // the previously saved one, so the SW didn't
        // receive any update and we can go to the app
        if (swHash === lastLaunchSwHash.current) {
            setIsCheckingUpdates(false);
        }
    };

    const renderChildrenIfNoInternetAccess = async () => {
        const status = await Network.getStatus();

        // Capacitor app can fetch service worker in offline mode
        // from it's own local server, so skip waiting for update
        // only for offline PWA users
        if (!status.connected && !Capacitor.isNativePlatform()) {
            setIsCheckingUpdates(false);
        }
    };

    useEffect(() => {
        if (!("serviceWorker" in navigator)) {
            // Our browser doesn't support service workers, skip update
            setIsCheckingUpdates(false);

            LogService.logEvent({
                name: events.pwa.updateChecker.swUnsupported,
                properties: { pwaCurrentVersionNumber }
            });
            return;
        }

        // If the update didn't download in 20 seconds, the user has terrible
        // Internet connection or something went horribly wrong, skip
        updateTimeout.current = setTimeout(() => {
            setIsCheckingUpdates(false);

            LogService.logEvent({
                name: events.pwa.updateChecker.timeoutReached,
                properties: { pwaCurrentVersionNumber }
            });
        }, constants.pwa.updateTimeoutInSeconds * 1000);

        // Fired only when there is no active SWs, so on the initial install
        window.addEventListener(constants.pwa.events.swInstalledFirstTime, () => {
            setIsCheckingUpdates(false);
        });

        // Fired each time the SW gets updated (differs by at least one byte)
        // and possibly after calling swRegistration.update();
        window.addEventListener(constants.pwa.events.swUpdateAvailable, handleUpdateAvailable);

        // Fired after applying an update, meaning we have
        // to refresh the page to apply the actual changes
        navigator.serviceWorker.addEventListener("controllerchange", handleControllerChange);

        // Fired on message from service worker - used to pass update information
        // between the app and SW
        navigator.serviceWorker.addEventListener("message", handleNewSwMessage);

        // Used for checking updates, when there were no connection
        // available on the initial offline app launch
        Network.addListener("networkStatusChange", handleNetworkChange);

        // Listen for the "Add to home screen" prompt and log results for metrics
        // Note that currently this only works on Chrome and Android
        window.addEventListener("beforeinstallprompt", async (e: any) => {
            LogService.logEvent({
                name: events.pwa.updateChecker.swInstallationPromptShown,
                properties: { pwaCurrentVersionNumber, platforms: e.platforms }
            });
        });

        // beforeinstallprompt's event has user response promise, but it didn't
        // work properly, so we will base the conversion on appinstalled event
        window.addEventListener("appinstalled", () => {
            LogService.logEvent({
                name: events.pwa.updateChecker.addedToHomeScreen,
                properties: { pwaCurrentVersionNumber }
            });
        });

        (async () => {
            prevSwState.current = (await getServiceWorkerRegistration())?.active?.state;

            await renderChildrenIfNoInternetAccess();

            serviceWorkerRegistration.register();
        })();
    }, [handleControllerChange, handleUpdateAvailable]);

    return process.env.NODE_ENV === "production" && isCheckingUpdates ? (
        <Loader customText={pl.pwa.updatePending} />
    ) : (
        <> {children} </>
    );
};

export default UpdateAvailableDialog;
