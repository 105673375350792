import md5 from "md5";
import { constants, events as logEvents, pwaCurrentVersionNumber } from "shared/constants";
import { LogService } from "ecom-selfcare-apps-core";
const { events, localStorage: pwaLocalStorage } = constants.pwa;

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.0/8 are considered localhost for IPv4.
        window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

type Config = {
    onSuccess?: (registration: ServiceWorkerRegistration) => void;
    onUpdate?: (registration: ServiceWorkerRegistration) => void;
};

export function register(config?: Config) {
    if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
        // The URL constructor is available in all browsers that support SW.
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // Our service worker won't work if PUBLIC_URL is on a different origin
            // from what our page is served on. This might happen if a CDN is used to
            // serve assets; see https://github.com/facebook/create-react-app/issues/2374
            return;
        }

        if (isLocalhost) {
            // This is running on localhost. Let's check if a service worker still exists or not.
            checkValidServiceWorker(constants.pwa.swUrl, config);

            // Add some additional logging to localhost, pointing developers to the
            // service worker/PWA documentation.
            navigator.serviceWorker.ready.then(() => {
                console.log(
                    "This web app is being served cache-first by a service " +
                        "worker. To learn more, visit https://cra.link/PWA"
                );
            });
        } else {
            // Is not localhost. Fetch the script to update
            // SW hash and just register the service worker.
            fetch(constants.pwa.swUrl);
            registerValidSW(constants.pwa.swUrl, config);
        }
    }
}

const updateSwHash = async (swUrl: string) => {
    // Fetch the service worker
    const response = await fetch(swUrl);

    if (!response || !response.body) {
        return;
    }

    const data = await response.clone().arrayBuffer();
    const body = new TextDecoder("utf-8").decode(data);

    // Calculate service worker's code hash
    const swHash = md5(body);

    // Store it in local storage for further comparisons
    localStorage.setItem(pwaLocalStorage.swUpdateHash, swHash);
};

export function registerValidSW(swUrl: string, config?: Config) {
    navigator.serviceWorker.getRegistrations().then(async (data) => {
        // Skip waiting for updates for new users, since it's their first SW install
        if (data.length <= 0) {
            window.dispatchEvent(new CustomEvent(events.swInstalledFirstTime));
            await updateSwHash(swUrl);
            LogService.logEvent({
                name: logEvents.pwa.registration.swInstalledFirstTime,
                properties: { pwaCurrentVersionNumber }
            });
        }
    });

    navigator.serviceWorker
        .register(swUrl)
        .then(async (registration) => {
            registration.onupdatefound = () => {
                LogService.logEvent({
                    name: logEvents.pwa.registration.updateFound,
                    properties: { pwaCurrentVersionNumber }
                });

                const installingWorker = registration.installing;

                if (installingWorker == null) {
                    return;
                }

                installingWorker.onstatechange = async () => {
                    if (installingWorker.state !== "installed") {
                        return;
                    }

                    if (navigator.serviceWorker.controller) {
                        // At this point, the updated precached content has been fetched,
                        // but the previous service worker will still serve the older
                        // content until all client tabs are closed.
                        // To avoid this, we display a loader while checking SW updates,
                        // and then hide after the update completes.
                        window.dispatchEvent(new CustomEvent(events.swUpdateAvailable));
                        LogService.logEvent({
                            name: logEvents.pwa.registration.swUpdateAvailableDispatch,
                            properties: { pwaCurrentVersionNumber }
                        });

                        // Execute callback
                        if (config && config.onUpdate) {
                            config.onUpdate(registration);
                        }
                    } else {
                        // At this point, everything has been precached.
                        // It's the perfect time to display a
                        // "Content is cached for offline use." message.
                        console.log("Content is cached for offline use.");

                        // Execute callback
                        if (config && config.onSuccess) {
                            config.onSuccess(registration);
                        }
                    }
                };
            };
        })
        .catch((error) => {
            const message = "Error during service worker registration:";
            LogService.logException({
                exception: new Error(`${message} ${error.message}`),
                properties: { pwaCurrentVersionNumber }
            });
            console.error(message, error);
        });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
    // Check if the service worker can be found. If it can't reload the page.
    fetch(swUrl, {
        headers: { "Service-Worker": "script" }
    })
        .then((response) => {
            // Ensure service worker exists, and that we really are getting a JS file.
            const contentType = response.headers.get("content-type");
            if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
                // No service worker found. Probably a different app. Reload the page.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker found. Proceed as normal.
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log("No internet connection found. App is running in offline mode.");
        });
}

export function unregister() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                const message = "Error during service worker un-registration: ";
                LogService.logException({
                    exception: new Error(`${message} ${error.message}`),
                    properties: { pwaCurrentVersionNumber }
                });
                console.error(message, error.message);
            });
    }
}
