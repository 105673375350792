import React from "react";
import styles from "./app.module.scss";
import Routes from "routes/Routes";
import { Footer } from "ecom-selfcare-apps-core";
import FooterContent from "shared/footer/FooterContent";
import UpdateChecker from "pwa/UpdateChecker";

const App: React.FC = () => {
    return (
        <div className={styles.app}>
            <UpdateChecker>
                <Routes />
                <Footer>
                    <FooterContent />
                </Footer>
            </UpdateChecker>
        </div>
    );
};

export default App;
