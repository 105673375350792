import React from "react";
import styles from "./footerContent.module.scss";
import { constants } from "../constants";
import pl from "../messages/pl";

const FooterContent: React.FC = () => {
    return (
        <p className={styles.content}>
            {pl.footer.text()}
            {process.env.REACT_APP_FF_REGULATIONS_ENABLED === "true" && (
                <a className={styles.privacyPolicy} href={constants.footer.privacyPolicy}>
                    {pl.footer.privacyPolicyLink}
                </a>
            )}
        </p>
    );
};

export default FooterContent;
