import React from "react";
import styles from "./noPoliciesContact.module.scss";
import pl from "../shared/messages/pl";
import PhoneIcon from "../shared/svgs/PhoneIcon.svg";
import { constants } from "../shared/constants";

const NoPoliciesContact: React.FC = () => {
    return (
        <>
            <p>
                <img className={styles.phoneIcon} src={PhoneIcon} alt={pl.policies.noPolicies.imageAlt} />
                {pl.policies.noPolicies.contact}
                <a
                    className={styles.link}
                    href={`tel:${constants.contact.phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={constants.contact.phone}
                >
                    {constants.contact.phone}
                </a>
            </p>
        </>
    );
};

export default NoPoliciesContact;
