const nbsp: string = "\u00A0";

const pl = {
    common: {
        close: "Zamknij",
        confirm: "Potwierdź",
        logoAlt: "Logo Ubezpieczenia od A do Z",
        heroImageAlt: "Ilustracja samochodu",
        noInternetConnection: "Brak połączenia z Internetem",
        exitConfirmation: "Naciśnij ponownie, aby wyjść z aplikacji",
        closeRedirectionPageManually: "Jeżeli powrót nie nastąpi automatycznie, zamknij zakładkę ręcznie."
    },
    footer: {
        text: () => `Copyright © ${new Date().getFullYear()} Ubezpieczenia od A do Z`,
        privacyPolicyLink: "Polityka prywatności"
    },
    mobileAppBanner: {
        header: `Aplikacja mobilna Strefy${nbsp}Klienta`,
        text: "Zainstaluj ją na swoim urządzeniu",
        action: "Pobierz"
    },
    login: {
        backToLoginForm: "Powrót na stronę logowania"
    },
    policies: {
        noPolicies: {
            content: `Skontaktuj się z Doradcą Ubezpieczenia${nbsp}od${nbsp}A${nbsp}do${nbsp}Z w celu zakupu nowej polisy`,
            contact: " Zadzwoń do nas ",
            imageAlt: "Ikona telefonu"
        }
    },
    pwa: {
        updateAvailableAlert: {
            title: "Dostępna aktualizacja",
            description: "Odśwież, by uzyskać dostęp do najnowszych zmian",
            refresh: "Odśwież",
            cancel: "Anuluj"
        },
        updatePending: "Trwa aktualizowanie aplikacji..."
    }
};

export default pl;
