import { App as CapacitorApp } from "@capacitor/app";
import { Toast } from "@capacitor/toast";
import pl from "../shared/messages/pl";
import { constants } from "../shared/constants";
import { NavigationHelper } from "ecom-selfcare-apps-core";
import { SplashScreen } from "@capacitor/splash-screen";

export function register() {
    CapacitorApp.addListener("backButton", async ({ canGoBack }) => {
        backButtonPressedHandler(canGoBack);
    });

    document.addEventListener("deviceready", onDeviceReady, false);
}

export const navigationHelper = new NavigationHelper();

let lastBackPressedTime = 0;

async function onDeviceReady() {
    await SplashScreen.hide();
}

async function backButtonPressedHandler(canGoBack: boolean) {
    if (navigationHelper.isCardToClose()) {
        return;
    }

    if (canGoBack) {
        window.history.back();
        return;
    }

    if (lastBackPressedTime + constants.pwa.backPressedTimeIntervalInMilliseconds > new Date().getTime()) {
        CapacitorApp.exitApp();
    }

    await Toast.show({ text: pl.common.exitConfirmation });
    lastBackPressedTime = new Date().getTime();
}
